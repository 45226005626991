<template>
  <div class="iss-massdata">
    <div class="massdata-wrapper">
      <div class="statistics-card mb-16">
        <div class="flex">
          <span>发送数据</span>
          <a href="skipLink" style="color: #333; text-decoration: underline"
            >关联公众号</a
          >
          <!-- <div
            @click="skipUrl"
            class="fontStyle"
            style="color: #333; text-decoration: underline"
          >
            关联公众号
          </div> -->
        </div>
        <a-row style="text-align: center" class="mt-42">
          <a-col :span="4">
            <a-space direction="vertical" style="text-align: left">
              <div>表单填写提醒</div>
              <div>
                <span>发送时间：</span><span>{{ recordData.sendTime }}</span>
              </div>
              <div class="flex" style="justify-content: flex-start">
                <span>发送对象：</span>
                <span v-if="!(tagList?.length > 0)"> 全部粉丝 </span>
                <div class="fan-box" v-else @click.stop="showModal()">
                  粉丝标签 <DownOutlined class="ml-10" />
                  <div ref="modalRef" v-if="show" class="tag-modal">
                    <p v-for="(item, index) in tagList" :key="index">
                      <a-tooltip :title="item">
                        <span class="ellipsis">{{ item }}</span>
                      </a-tooltip>
                    </p>
                  </div>
                </div>
              </div>
            </a-space>
          </a-col>
          <a-col :span="6">
            <p>发送人数</p>
            <p class="num">{{ recordData.sendCount }}</p>
          </a-col>
          <a-col :span="7">
            <p>发送成功人数</p>
            <p class="num">{{ recordData.successCount }}</p>
          </a-col>
          <a-col :span="7">
            <p>点击打开数</p>
            <p class="num">{{ recordData.openCount }}</p>
          </a-col>
        </a-row>
      </div>
      <div class="statistics-card">
        <div class="mb-42">发送对象</div>
        <grid
          ref="gridRef"
          :allow-selection="false"
          :columns="columns"
          :code="$route.path"
          :url="url"
          :url-params="{
            batchId,
            sendTemplateId,
            appId: $store.state.account.appId,
          }"
          :btn-operation="null"
          :scroll="{ x: 1200, y: gridHeight }"
        >
          <template #sendStatus="{ record }">
            <!-- <span v-if="record.sendStatus === 1" style="color: red"
              >发送失败
              <a-tooltip :title="record.errorMsg">
                <InfoCircleOutlined class="mr-10 pt-20" />
              </a-tooltip>
            </span> -->
            <span
              v-if="record.sendStatus === 1"
              style="color: red"
              @click="showErrorModal(record)"
              >发送失败

              <InfoCircleOutlined class="mr-10 pt-20" />
            </span>
            <span v-if="record.sendStatus === 2">发送中</span>
            <span v-if="record.sendStatus === 0">发送成功</span>
          </template>
        </grid>
      </div>
    </div>
    <!-- 失败原因弹框 -->
    <a-modal
      v-model:visible="failVisible"
      width="540px"
      :closable="false"
      :centered="true"
    >
      <p style="text-align: center">
        <InfoCircleOutlined style="color: red" class="mr-10 pt-20" /><span
          >发送失败</span
        >
      </p>
      <p style="text-align: center">{{ activeRecord.errorMsg }}</p>
      <template #footer>
        <a-space>
          <a-button type="primary" @click="failVisible = false">
            确认
          </a-button>
        </a-space>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { Col, Row, Button, Space, Tooltip } from 'ant-design-vue';
import {
  reactive,
  toRefs,
  onMounted,
  onUnmounted,
  ref,
  onBeforeUnmount,
} from 'vue';

import wechatApi from '@/api/wechatServer';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Grid from '@/components/grid';
import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons-vue';
// import moment from 'moment';
export default {
  components: {
    Grid,
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ASpace: Space,
    ATooltip: Tooltip,
    DownOutlined,
    InfoCircleOutlined,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    // const router = useRouter();
    const batchId = route.params.id;
    const sendTemplateId = route.params.sendTemplateId;
    const state = reactive({
      show: false,
      failVisible: false,
      appId: store.state.account.appId,
      recordData: {},
      tagList: [],
      activeRecord: {},
    });
    const modalRef = ref(null);
    const gridRef = ref(null);

    const _init = async () => {
      document.addEventListener('click', handleClickOutside);
      // await wechatApi.getTenantId().then(res => {
      //   if (!res) {
      //     console.log(res);
      //   } else {
      //     state.appId = res.appId[0].appId;
      //     store.commit('account/setAppId', res.appId[0].appId);
      //   }
      // });
      getAllData();
    };

    const getAllData = () => {
      let data = {
        appId: state.appId,
        batchId,
        sendTemplateId,
      };

      wechatApi.getTemplateRecords({}, data).then(res => {
        if (res) {
          state.recordData = res;
          state.tagList = !res.tagList ? [] : res.tagList;
        }
      });
    };
    const handleClickOutside = event => {
      if (modalRef.value && !modalRef.value.contains(event.target)) {
        state.show = false;
      }
    };
    onMounted(async () => {
      _init();
    });
    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });
    onUnmounted(() => {});
    return {
      //   skipLink,
      gridRef,
      modalRef,
      batchId,
      sendTemplateId,
      url: wechatApi.singleRecordUrl,
      gridHeight: document.body.clientHeight - 419,
      ...toRefs(state),
      columns: [
        {
          dataIndex: 'openId',
          title: 'openId',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'sendTime',
          title: '发送时间',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'sendStatus',
          title: '发送状态',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'sendStatus' },
        },

        {
          dataIndex: 'lastClickTime',
          title: '点击时间',
          width: 180,
          ellipsis: true,
        },
      ],
      showModal() {
        state.show = !state.show;
      },
      showErrorModal(record) {
        state.activeRecord = record;
        state.failVisible = true;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-massdata {
  padding: 16px;
  .massdata-wrapper {
    padding: 10px;
    margin: -10px;
    height: calc(100vh - 68px);
    overflow-y: auto;
  }
}
.statistics-card {
  border-radius: 4px;
  padding: 20px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
}

.graph-card,
.table-card {
  min-height: 352px;
  padding: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
}
.num {
  color: #5979f8;
  font-size: 28px;
  font-weight: 600;
}
.fontStyle {
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313d5f;
  line-height: 24px;
}

.fan-box {
  position: relative;
  z-index: 99;
  cursor: pointer;

  .tag-modal {
    width: 100%;
    max-height: 140px;
    overflow-y: auto;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 0px 6.3px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    text-align: center;
    padding: 16px 10px;
    cursor: initial;
    p {
      margin-bottom: 5px;
    }
  }
}
.time-btn {
  border-radius: 99px;
  background: #d6d6d6;
  color: #666666;
  border-color: transparent;
}
:deep(.ant-btn-primary) {
  border-radius: 99px;
}
:deep(
    .ant-table-tbody
      > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
        .ant-table-row-selected
      )
      > td
  ) {
  background: #fff;
}
.ellipsis {
  display: block;
  white-space: nowrap; /* 确保文本不会换行 */
  overflow: hidden; /* 隐藏超出的内容 */
  text-overflow: ellipsis; /* 当内容被裁剪时显示省略号 */
}
</style>
